.container {
    // h3, .gameLink {
    //     display: table-cell;
    //     padding: 1rem;
    // }

    h3 {
        display: flex;
        align-items: center;
        font-size: 1.618rem;
        font-weight: bold;
        margin-bottom: 0.8rem;
        line-height: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: calc(100% - 4rem);

        a {
            color: black;
        }

        button {
            position: relative;
            top: 0.15em;
            margin-left: 0.2rem;
        }
    }

    .link {
        display: flex;
        border: thin solid #ccc;
        // border-radius: 0.25rem;

        input {
            border: none;
            padding: 0.5rem 1rem;
            background: white;
            color: black;
            flex-grow: 1;
            font-size: 1rem;
            min-width: 0;
        }
    }

    .buttons {
        display: flex;
        margin-top: 1rem;

        button + button {
            margin-left: 1rem;
        }

        @media (max-width: 45em) {
            flex-direction: column;
            button + button {
                margin-left: 0;
                margin-top: 1rem;
            }
        }
    }
}

.uriField {
    font-family: Cabin, sans-serif;
    display: flex;
    align-items: center;

    *[class*="mantine-TextInput-root"] {
        flex-grow: 1;
        margin-left: 1rem;
    }
}

.uriNotAvailable {
    font-family: Cabin, sans-serif;
    margin-top: 1rem;
    line-height: 1.5;
}