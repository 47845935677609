.container {
    padding: 1.618rem;
    font-family: Cabin;
    
    .welcome {
        &, form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            
            &>* {
                margin-bottom: 1rem;
            }
        }

        h2 {
            font-size: 2.618rem;
            font-weight: bold;
        }

        *[class*="mantine-TextInput-root"] input {
            width: 75%;
            min-width: 20rem;
        }

        button {
            margin-top: 0.5rem;
        }
    }

    .wordList {
        &, form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        
            &>*{
                margin-bottom: 1rem;
            }
        }

        h2 {
            font-size: 2.618rem;
            font-weight: bold;
        }

        textarea {
            width: 80%;
            min-width: 20rem;
        }

        button {
            margin-top: 0.5rem;
        }
    }
}

.signInModal {
    display: flex;
    flex-direction: column;
    font-family: Cabin;

    h2 {
        font-weight: bold;
        font-size: 1.618rem;
    }

    p {
        line-height: 1.5;
    }

    p, button {
        margin-top: 1rem;
    }
}