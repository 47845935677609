.container {
    font-size: 16px;
    font-family: Cabin;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    header {
        display: flex;
        padding: 1rem;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        h1 {
            font-size: 1.618rem;
            line-height: 1;
            font-weight: 800;

            a {
                color: black;
                text-decoration: none;
            }
        }
    }

    main {
        flex-grow: 1;
        padding: 1rem;
    }

    footer {
        padding: 1rem;
        display: flex;
        gap: 1.618rem;
        justify-content: center;
        align-items: center;
    }
}

.authHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    p, a {
        margin-right: 1rem;
        font-size: 0.8em;
        color: black;
    }
}