.container {
    background: #f3f3f3;
    margin: -1rem;
    padding: 2.618rem;
    min-height: calc(100vh - 12rem);
    @media (max-width: 45em) {
        padding: 1.618rem;
        min-height: calc(100vh - 10rem);
    }

    display: flex;
    flex-direction: column;
    font-family: Cabin;

    h2 {
        font-weight: 800;
        font-size: calc(1.618rem * 1.618);
        line-height: 1;
        padding-bottom: 1.418rem;
        margin-bottom: 1.618rem;
        border-bottom: 0.2rem solid black;
        // text-align: center;
    }

    // p {
    //     line-height: 1.5;
    // }

    // p, button {
    //     margin-top: 1rem;
    // }

    // ul {
    //     display: table;

    //     li {
    //         display: table-row;

    //         &:nth-of-type(even) {
    //             background: lighten(#d8e4ee, 7%);
    //         }
    //     }
    // }
}